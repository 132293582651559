<template>
  <div class="sidebar" :data-color="itemColor">
    <div class="logo">
      <a href="/" class="simple-text logo-normal">
        <img :src="imgLogo" alt="Boote" class="img" />
      </a>
    </div>
    <div class="sidebar-wrapper">
      <slot name="content"></slot>
      <ul class="nav">
        <slot>
          <template v-for="(item, i) in sidebarLinks">
            <sidebar-link :key="`item-${i}`" :item="item"></sidebar-link>
          </template>
        </slot>
      </ul>

      <ul class="nav">
        <li class="nav-item">
          <logout-button></logout-button>
        </li>
      </ul>
    </div>
    <div class="sidebar-background"></div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    title: {
      type: String,
      default: "panel.title",
    },
    imgLogo: {
      type: String,
      default: require("../../../assets/logo/logo_large.png"),
    },
    backgroundColor: {
      type: String,
      default: "black",
      validator: (value) => {
        let acceptedValues = ["", "white", "black"];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    itemColor: {
      type: String,
      default: "purple",
      validator: (value) => {
        let acceptedValues = [
          "",
          "purple",
          "azure",
          "green",
          "orange",
          "rose",
          "danger",
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
@media screen and (min-width: 991px) {
  .nav-mobile-menu {
    display: none;
  }
}

.img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  padding: 0 1em;
}
</style>
