const set = (key) => (state, val) => {
  state[key] = val;
};

function initialState() {
  return {
    data: [],
    total: 0,
    query: {},
    loading: false,
  };
}

const route = "assets";

const getters = {
  data: (state) => state.data,
  total: (state) => state.total,
  loading: (state) => state.loading,
  herd: (state) => state.herd,
};

const actions = {
  fetchIndexData({ commit, state, dispatch }) {
    commit("setLoading", true);
    axios
      .get(route, {
        params: state.query,
      })
      .then((response) => {
        commit("setData", response.data.data);
        commit("setTotal", response.data.paginatorData.total);
      })
      .then(() => {
        commit("setLoading", false);
      })
      .catch((error) => {
        console.log(error);
        let errors = error.response.data.messages.message;

        dispatch(
          "Alert/setAlert",
          {
            message: "global.error",
            errors: [errors],
            color: "danger",
          },
          { root: true }
        );
      });
  },
  setQuery({ commit }, value) {
    commit("setQuery", _.cloneDeep(value));
  },
  resetState({ commit }) {
    commit("resetState");
  },
};

const mutations = {
  setData(state, value) {
    state.data = value;
  },
  setTotal: set("total"),
  setQuery(state, query) {
    query.page = (query.offset + query.limit) / query.limit;
    state.query = query;
  },
  setHerd(state, value) {
    state.herd = value;
  },
  setLoading: set("loading"),
  resetState(state) {
    Object.assign(state, initialState());
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
