<template>
  <a href="#" class="nav-link" @click.prevent="doLogout">
    <i class="material-icons">power_settings_new</i>
    <p>{{ $t("auth.logout") }}</p>
  </a>
</template>

<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("Auth", ["logout"]),
    doLogout() {
      this.logout()
        .then(() => {
          this.$router.push({ name: "login" });
          this.$eventHub.$emit("logout-success");
        })
        .catch(error => {
          this.status = "failed";
          _.delay(() => {
            this.status;
          });
        });
    }
  }
};
</script>