import { abilitiesPlugin } from "@casl/vue";
import ability from "./services/ability";
import Datatable from "@laraveldaily/vue2-datatable";
import VueSweetalert2 from "vue-sweetalert2";

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

import MaterialSpinner from "./components/MaterialSpinner.vue";
import VueButtonSpinner from "./components/VueButtonSpinner.vue";
import BootstrapAlert from "./components/BootstrapAlert.vue";
import SideBar from "./components/SidebarPlugin";
import LanguagesPlugin from "./components/LanguagesPlugin";
import EventHub from "./components/EventHubPlugin";
import BackButton from "./components/BackButton.vue";
import DatetimePicker from "./components/DatetimePicker.vue";
import VueRadio from "./components/VueRadio.vue";
import LogoutButton from "./components/LogoutButton.vue";
import vSelect from "vue-select";

//Leaflet map
import {
	LMap,
	LTileLayer,
	LMarker,
	LPopup,
	LPolygon,
	LControl,
	LPolyline,
	LIcon,
} from "vue2-leaflet";
import LFreedraw from "vue2-leaflet-freedraw";
import Vue2LeafletPolylineDecorator from "vue2-leaflet-polylinedecorator";

const GlobalComponents = {
	install(Vue) {
		Vue.use(abilitiesPlugin, ability);
		Vue.use(SideBar);
		Vue.use(LanguagesPlugin);
		Vue.use(EventHub);
		Vue.use(Datatable);
		Vue.use(VueSweetalert2);
		Vue.component("datetime-picker", DatetimePicker);
		Vue.component("back-button", BackButton);
		Vue.component("material-spinner", MaterialSpinner);
		Vue.component("vue-button-spinner", VueButtonSpinner);
		Vue.component("bootstrap-alert", BootstrapAlert);
		Vue.component("v-radio", VueRadio);
		Vue.component("v-select", vSelect);
		Vue.component("logout-button", LogoutButton);
		Vue.component("l-map", LMap);
		Vue.component("l-tile-layer", LTileLayer);
		Vue.component("l-marker", LMarker);
		Vue.component("l-icon", LIcon);
		Vue.component("l-popup", LPopup);
		Vue.component("l-polygon", LPolygon);
		Vue.component("l-control", LControl);
		Vue.component("l-freedraw", LFreedraw);
		Vue.component("l-polyline-decorator", Vue2LeafletPolylineDecorator);
		Vue.component("l-polyline", LPolyline);
	},
};

export default GlobalComponents;
