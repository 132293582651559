function initialState() {
  return {
    asset: {
      id: -1,
      name: "",
      currentMode: "",
      coordinates: {
        coordinates: [0, 0],
      },
    },
    herd: {
      geofence: {
        coordinates: [
          [0, 0],
          [1, 1],
          [2, 2],
          [3, 3],
        ],
      },
    },
    assetHistory: [],
    assetAttachment: [],
    loading: false,
    isChangingMode: false,
  };
}

const route = "assets";

const getters = {
  asset: (state) => state.asset,
  herd: (state) => state.herd,
  assetHistory: (state) => state.assetHistory,
  assetAttachment: (state) => state.assetAttachment,
  loading: (state) => state.loading,
  isChangingMode: (state) => state.isChangingMode,
};

const actions = {
  updateAttachment({ state, commit }, data) {
    commit("setAssetAttachment", data);
  },
  deleteAttachment({ state, commit }, data) {
    axios
      .get(`${route}/${data.attachment_id}/deleteAttachment`)
      .then((response) => {
        commit("setAssetAttachment", response.data.data.attachment);
      });
  },
  deleteMultipleAttachment({ state, commit }, data) {
    axios
      .get(`${route}/delete/multipleAttachment`, {
        params: {
          attachments: data,
        },
      })
      .then((response) => {
        commit("setAssetAttachment", response.data.data.attachment);
      });
  },
  fetchShowData({ state, commit, dispatch }, data) {
    commit("setLoading", true);
    axios
      .get(`${route}/${data.id}`)
      .then((response) => {
        commit("setAsset", response.data.data);
        commit("setAssetAttachment", response.data.data.attachment);
        if (data.shouldFetchHerd) {
          axios
            .get(`herds/${state.asset.herd_id}/geofence`)
            .then((response) => {
              commit("setHerd", response.data.data);
            })
            .then(() => {
              commit("setLoading", false);
            })
            .catch((error) => {
              console.log(error);

              let errors = error.data.messages.message;

              dispatch(
                "Alert/setAlert",
                {
                  message: "global.error",
                  errors: [errors],
                  color: "danger",
                },
                {
                  root: true,
                }
              );
            });
        }
      })
      .then(() => {
        commit("setLoading", false);
      })
      .catch((error) => {
        console.error(error);

        let errors = error.response.data.messages.message;

        dispatch(
          "Alert/setAlert",
          {
            message: "global.error",
            errors: [errors],
            color: "danger",
          },
          { root: true }
        );
      });
  },

  fetchAssetHistory({ commit, dispatch}, data) {
    commit("setLoading", true);
    axios
      .get(
        `${route}/${data.id}/coordinates/history?sort=updated_at&order=asc`,
        {
          params: {
            from: data.from,
            to: data.to,
            id: data.id,
          },
        }
      )
      .then((response) => {
        commit("resetAssetHistory");
        commit("setAssetHistory", response.data.data);
      })
      .then(() => {
        commit("setLoading", false);
      })
      .catch((error) => {
        console.log(error);

        let errors = error.response.data.messages.message;

        dispatch(
          "Alert/setAlert",
          {
            message: "global.error",
            errors: [errors],
            color: "danger",
          },
          { root: true }
        );
      });
  },
  
  changeMode({ commit, dispatch }, data) {
    commit("setIsChangingMode", true);
    console.log(data);
    axios
      .post(`${route}/${data.id}/changeMode`, { mode: data.mode, id: data.id })
      .then(() => {
        dispatch("fetchShowData", { id: data.id, shouldFetchHerd: false });
      })
      .catch((error) => {
        console.log(error)
        let errors = error.response.data.messages.message;

        dispatch(
          "Alert/setAlert",
          { message: "global.error", errors: [errors], color: "danger" },
          { root: true }
        );
      })
      .finally(() => {
        commit("setIsChangingMode", false);
      });
  },
  resetState({ commit }) {
    commit("resetState");
  },
  resetAssetHistory({ commit }) {
    commit("resetAssetHistory");
  },
};

const mutations = {
  setAsset(state, asset) {
    state.asset = asset;
  },
  setHerd(state, herd) {
    state.herd = herd;
  },
  setAssetAttachment(state, data) {
    state.assetAttachment = [];
    Object.entries(data).forEach(([key, attachments]) => {
      state.assetAttachment.push(attachments);
    });
  },
  setAssetHistory(state, data) {
    data.forEach((assetCoordinates) => {
      state.assetHistory.push(assetCoordinates);
    });
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setIsChangingMode(state, loading) {
    state.loading = loading;
  },
  resetState(state) {
    state = Object.assign(state, initialState());
  },
  resetAssetHistory(state) {
    state.assetHistory = [];
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
