import axios from "axios";

function initialState() {
  return {
    entry: {
      email: "",
      password: "",
    },
    token: window.localStorage.getItem("user-token") || "",
    loading: false,
  };
}

const prefix = "/auth";

const getters = {
  entry: (state) => state.entry,
  token: (state) => state.token,
  loading: (state) => state.loading,
  isAuthenticated: (state) => !!state.token,
};

const actions = {
  login({ commit, state, dispatch }) {
    commit("setLoading", true);
    dispatch("Alert/resetState", null, { root: true });

    return new Promise((resolve, reject) => {
      let params = state.entry;
      axios
        .post(`${prefix}/login`, params)
        .then((response) => {
          const token = response.data.data.access_token;
          window.localStorage.setItem("user-token", token);
          commit("loginSuccess", token);
          resolve(response);
        })
        .catch((error) => {
          let message = error.response.data.messages || error.messages;
          let errors = error.response.data.errors;

          dispatch(
            "Alert/setAlert",
            { message: message.message, errors: errors, color: "danger" },
            { root: true }
          );
          window.localStorage.removeItem("user-token");
          reject(error);
        });
    });
  },
  logout({ commit, dispatch }) {
    dispatch("Alert/resetState", null, { root: true });

    return new Promise((resolve, reject) => {
      axios.post(`${prefix}/logout`).then(() => {
        commit("logout");
        window.localStorage.removeItem("user-token");
        resolve();
      });
    });
  },
  setEmail({ commit }, value) {
    commit("setEmail", value);
  },
  setPassword({ commit }, value) {
    commit("setPassword", value);
  },
  setLoading({ commit }, value) {
    commit("setLoading", value);
  },
  resetState({ commit }, value) {
    commit("resetState", value);
  },
};

const mutations = {
  loginSuccess(state, token) {
    state.token = token;
  },
  logout(state) {
    state.token = "";
  },
  setEmail(state, value) {
    state.entry.email = value;
  },
  setPassword(state, value) {
    state.entry.password = value;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  resetState(state) {
    state = Object.assign(state, initialState());
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
