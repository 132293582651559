import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const View = { template: "<router-view></router-view>" };

const routes = [
  {
    path: "/login",
    component: () => import("../pages/Auth/Login.vue"),
    name: "login",
    meta: {
      title: "Infosfera - Login",
      requiresAuth: false,
    },
  },
  {
    path: "/forgot-password",
    component: () => import("../pages/Auth/ForgotPassword.vue"),
    name: "forgot-password",
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/reset-password",
    component: () => import("../pages/Auth/ResetPassword.vue"),
    name: "reset-password",
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/",
    component: () => import("../pages/Layout/DashboardLayout.vue"),
    redirect: "map",
    name: "index",
    children: [
      {
        path: "/map",
        name: "map",
        component: () => import("../pages/Content/Map.vue"),
      },
      {
        path: "/herds",
        name: "herds.index",
        component: () => import("../pages/Data/Herd/Index.vue"),
      },
      {
        path: "/herds/:id",
        name: "herds.show",
        component: () => import("../pages/Data/Herd/Element.vue"),
      },
      {
        path: "/assets",
        name: "assets.index",
        component: () => import("../pages/Data/Asset/Index.vue"),
      },
      {
        path: "/assets/:id",
        name: "assets.show",
        component: () => import("../pages/Data/Asset/Element.vue"),
      },
      {
        path: "assetAlerts",
        name: "assetAlerts.index",
        component: () => import("../pages/Data/AssetAlert/Index.vue"),
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // window.axios
    //   .post("/auth/refresh", localStorage.getItem("user-token"))
    //   .then((response) => {
    //     console.log(response);
    //     const token = response.data.data.access_token;
    //     window.localStorage.setItem("user-token", token);
    //   });
    if (window.localStorage.hasOwnProperty("user-token")) {
      next();
    } else {
      next({ path: "/login" });
    }
  } else {
    if (!window.localStorage.hasOwnProperty("user-token")) {
      next();
    } else {
      next({ path: "/" });
    }
  }
});

export default router;
