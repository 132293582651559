import i18n from "../../i18n";

import languages from '../../../locales/languages.json';

function getDefaultState() {
  return {
    languages: [],
    availableLocales: [],
  };
}

const getters = {
  locale: () => i18n.locale,
  languages: (state) => state.languages,
  availableLocales: (state) => state.availableLocales,
};

const actions = {
  fetchLanguages({ commit, dispatch }) {
    commit("setLanguages", languages)
    dispatch("setLocale", window.localStorage.locale ?? process.env.VUE_APP_I18N_FALLBACK_LOCALE)
  },
  setLocale({ commit, dispatch }, locale) {
    dispatch("fetchLocaleMessages", locale).then(() => {
      commit("setLocale", locale);
    });
  },
  fetchLocaleMessages({ commit, getters }, locale) {
    if (getters.availableLocales.indexOf(locale) !== -1) {
      return Promise.resolve();
    }

    commit("setAcceptLanguage", locale);

    return i18n.messages
  },
};

const mutations = {
  setLanguages(state, languages) {
    state.languages = languages;
  },
  setLocale(state, locale) {
    i18n.locale = locale;
    window.localStorage.locale = locale;
  },
  setMessages(state, { locale, messages }) {
    i18n.setLocaleMessage(locale, messages);
  },
  addAvailableLocale(state, locale) {
    state.availableLocales.push(locale);
  },
  setAcceptLanguage(state, locale) {
    axios.defaults.headers.common["Accept-Language"] = locale;
  },
};

export default {
  namespaced: true,
  state: getDefaultState,
  getters,
  actions,
  mutations,
};
