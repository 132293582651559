function initialState() {
    return {
        entry: {
            email: ""
        },
        loading: false,
    }
}

const route = "/auth/forgot-password";

const getters = {
    entry: (state) => state.entry,
    loading: (state) => state.loading,
}

const actions = {
    sendEmail({ commit, state, dispatch }) {
        commit("setLoading", true);
        dispatch("Alert/resetState", null, { root: true });

        return new Promise((resolve, reject) => {
            axios
                .post(route, {
                    "email": state.entry.email
                })
                .then((response) => {
                    let message = response.data.data.message;
                    dispatch(
                        "Alert/setAlert",
                        { message: message, color: "success" },
                        { root: true },
                    )
                    commit("setLoading", false);
                    resolve(response);
                })
                .catch((error) => {2
                    let errors = error.response.data.messages || error.messages;
                    dispatch(
                        "Alert/setAlert",
                        { message: 'global.error', errors: errors, color: "danger" },
                        { root: true }
                    );
                    commit("setLoading", false);
                    reject(error);
                })
        })
    },
    setEmail({ commit }, value) {
        commit("setEmail", value)
    }
};

const mutations = {
    setEmail(state, email) {
        state.entry.email = email;
    },
    setLoading(state, loading) {
        state.loading = loading;
    },
    resetState(state) {
        state = Object.assign(state, initialState());
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters, 
    actions,
    mutations,
}