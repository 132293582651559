var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{attrs:{"to":_vm.item.path},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item",class:[
      isActive && 'active',
      isExactActive && 'active',
      _vm.shouldActivate(_vm.item.activeFor) && 'active' ]},[_c('a',{staticClass:"nav-link",attrs:{"href":href},on:{"click":navigate}},[(_vm.text)?[_c('span',{staticClass:"sidebar-mini"},[_vm._v(_vm._s(_vm.textIcon))]),_c('span',{staticClass:"sidebar-normal"},[_vm._v(_vm._s(_vm.$t(_vm.item.title)))])]:[_c('i',{staticClass:"material-icons"},[_vm._v(_vm._s(_vm.item.icon))]),_c('p',[_vm._v(_vm._s(_vm.$t(_vm.item.title)))])]],2)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }