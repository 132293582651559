require("./js/bootstrap");

import Vue from "vue";
import App from "./App.vue";

import router from "./js/routes/routes";
import store from "./js/store/store";
import i18n from "./js/i18n";

import GlobalComponents from "./js/globalComponents";
import GlobalDirectives from "./js/globalDirectives";
import GlobalMixins from "./js/mixins/global";
import { mapGetters, mapActions } from "vuex";

import style from "./sass/app.scss";
import "leaflet/dist/leaflet.css";

import { Icon } from "leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

window.Vue = require("vue");

Vue.config.productionTip = false;
// Vue.prototype.$jquery = $

Vue.use(GlobalComponents);
Vue.use(GlobalDirectives);
Vue.use(GlobalMixins);

let app = null;

if (window.localStorage.hasOwnProperty("user-token")) {
    Promise.all([
      window.axios.post("/auth/refresh", localStorage.getItem("user-token")),
      new Promise(function(resolve) {
        setTimeout(resolve, 1000);
      })
    ])
    .then((responses) => {
      const token = responses[0].data.data.access_token;
      window.localStorage.setItem("user-token", token);

      initApp();
    });
} else {
  initApp();
}

function initApp() {
  app = new Vue({
    el: "#app",
    render: (h) => h(App),
    router,
    store,
    i18n,
    style,
    created() {
      this.fetchLanguages();
    },
    methods: {
      ...mapActions("I18NStore", ["fetchLanguages"]),
    },
  });
}