function initialState() {
  return {
    entry: {
      id: null,
      name: "",
      created_at: "",
    },
    herdLoading: false,
  };
}

const route = "herds";

const getters = {
  entry: (state) => state.entry,
  herdLoading: (state) => state.herdLoading,
};

const actions = {
  fetchShowData({ commit, dispatch }, id) {
    commit("setherdLoading", true);
    axios
      .get(`${route}/${id}`)
      .then((response) => {
        commit("setEntry", response.data.data);
      })
      .then(() => {
        commit("setherdLoading", false);
      })
      .catch((error) => {
        console.error(error);
        let errors = error.response.data.messages.message;

        dispatch(
          "Alert/setAlert",
          {
            message: "global.error",
            errors: [errors],
            color: "danger",
          },
          {
            root: true,
          }
        );
      });
  },
  resetState({ commit }) {
    commit("resetState");
  },
};

const mutations = {
  setEntry(state, entry) {
    state.entry = entry;
  },
  setherdLoading(state, herdLoading) {
    state.herdLoading = herdLoading;
  },
  resetState(state) {
    state = Object.assign(state, initialState());
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
