const set = (key) => (state, val) => {
  state[key] = val;
};

function initialState() {
  return {
    herds: [],
    assets: [],
    activeHerds: [],
    activeAssets: [],
    mapCenter: [41.68, 12.76],
    loading: false,
  };
}

const getters = {
  herds: (state) => state.herds,
  assets: (state) => state.assets,
  activeHerds: (state) => state.activeHerds,
  activeAssets: (state) => state.activeAssets,
  loading: (state) => state.loading,
  mapCenter: (state) => state.mapCenter,
};

const actions = {
  fetchData({ commit, dispatch, state }) {
    commit("setLoading", true);
    axios
      .get("herds/geofence") //TODO if testing with waynet data this should have /geofence
      .then((response) => {
        commit("setHerds", response.data.data);
        state.herds.forEach((herd) => {
          axios
            .get(`assets/coordinates?herd=${herd.id}`)
            .then((response) => {
              commit("setAsset", response.data.data);
            })
            .then(() => {
              if (state.herds[0].geofence.coordinates.length != 0) {
                dispatch(
                  "setMapCenter",
                  state.herds[0].geofence.coordinates[0]
                );
              }
              commit("setLoading", false);
            })
            .catch((error) => {
              console.log(error);
              let errors = error.response.data.messages.message;

              dispatch(
                "Alert/setAlert",
                {
                  message: "global.error",
                  errors: [errors],
                  color: "danger",
                },
                { root: true }
              );
            });
        });
      })
      .catch((error) => {
        console.log(error);
        let errors = error.response.data.messages.message;

        dispatch(
          "Alert/setAlert",
          {
            message: "global.error",
            errors: [errors],
            color: "danger",
          },
          { root: true }
        );
      });
  },

  setNewHerdGeoFence({ dispatch, commit }, data) {
    commit("setLoading", true);
    commit("resetHerds");
    commit("resetAsset");
    commit("resetActive");
    //formats the coordinates in a json format for server (array of array)
    let coordinates = [];
    data.geoFence.forEach((coordinate) => {
      coordinates.push([coordinate.lat, coordinate.lng]);
    });
    const geoFence = {
      coordinates: JSON.stringify(coordinates),
      herd_id: data.herdId,
    };
    axios
      .put(`herds/${data.herdId}/geofence/edit`, geoFence)
      .then(() => {
        dispatch("fetchData");
      })
      .catch((error) => {
        console.log(error);
        let errors = error.response.data.messages.message;

        dispatch(
          "Alert/setAlert",
          {
            message: "global.error",
            errors: [errors],
            color: "danger",
          },
          { root: true }
        );

        dispatch("fetchData");
      });
  },

  createNewHerdGeoFence({ dispatch, commit }, data) {
    commit("setLoading", true);
    commit("resetHerds");
    commit("resetActive");
    let coordinates = [];
    data.geoFence.forEach((coordinate) => {
      coordinates.push([coordinate.lat, coordinate.lng]);
    });
    const geoFence = {
      coordinates: JSON.stringify(coordinates),
      herd_id: data.herdId,
    };
    axios
      .put(`herds/${data.herdId}/geofence/create`, geoFence)
      .then(() => {
        dispatch("fetchData");
      })
      .catch((error) => {
        console.log(error);
        let errors = error.response.data.messages.message;

        dispatch(
          "Alert/setAlert",
          {
            message: "global.error",
            errors: [errors],
            color: "danger",
          },
          { root: true }
        );

        dispatch("fetchData");
      });
  },
  setActive({ commit }, id) {
    commit("setLoading", true);
    commit("setActive", id);
    commit("setLoading", false);
  },
  resetActive({ commit }, id) {
    commit("setLoading", true);
    commit("resetActive");
    commit("setLoading", false);
  },
  setMapCenter({ commit }, center) {
    commit("setMapCenter", center);
  },
  resetState({ commit }) {
    commit("resetState");
  },
};

const mutations = {
  setHerds(state, value) {
    state.herds = value;
    state.activeHerds = value;
  },
  setAsset(state, values) {
    values.forEach((value) => {
      state.assets.push(value);
      if (value.coordinates) {
        state.activeAssets.push(value);
      }
    });
  },
  setMapCenter(state, value) {
    state.mapCenter = value;
  },
  resetHerds(state) {
    state.herds = [];
    state.activeHerds = [];
  },
  resetAsset(state) {
    state.assets = [];
    state.activeAssets = [];
  },
  setLoading: set("loading"),
  resetState(state) {
    Object.assign(state, initialState());
  },
  setActive(state, id) {
    state.activeHerds = [];
    state.activeAssets = [];
    state.herds.forEach((herd) => {
      if (herd.id == id && herd.geofence.coordinates) {
        state.activeHerds.push(herd);
      }
    });
    state.assets.forEach((asset) => {
      if (asset.herd_id == id && asset.coordinates) {
        state.activeAssets.push(asset);
      }
    });
  },
  resetActive(state,id) {
    state.activeHerds = [];
    state.activeAssets = [];
    state.herds.forEach((herd) => {
      if (herd.id == id) {
        state.activeHerds.push(herd);
      }
    });
    state.assets.forEach((asset) => {
      if (asset.herd_id == id && asset.coordinates.coordinates) {
        state.activeAssets.push(asset);
      }
    });
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
