function initialState() {
    return{
        entry: {
            email: "",
            password: "",
            password_confirmation: "",
            token: "",
        },
        loading: false,
    }
}

const route = "/auth/reset-password";

const getters = {
    entry: (state) => state.entry,
    loading: (state) => state.loading,
}

const actions = {
    resetPassword({ commit, state, dispatch }) {
        commit("setLoading", true);
        dispatch("Alert/resetState", null, { root: true });

        return new Promise((resolve, reject) => {
            axios 
                .post(route, state.entry)
                .then((response) => {
                    let message = response.data.data.message;
                    dispatch(
                        "Alert/setAlert",
                        { message: message, color: "success" },
                        { root:true },
                    );
                    commit("setLoading", false);
                    resolve(response);
                })
                .catch((error) => {
                    let errors = error.response.data.messages || error.messages;
                    dispatch(
                        "Alert/setAlert",
                        { message: 'global.error', errors: errors, color: "danger" },
                        { root: true }
                    );
                    commit("setLoading", false);
                    reject(error);
                })
        })
    },
    setEmail({ commit }, value) {
        commit("setEmail", value);
    },
    setPassword({ commit }, value) {
        commit("setPassword", value);
    },
    setPasswordConfirmation({ commit }, value) {
        commit("setPasswordConfirmation", value);
    },
    setToken({ commit }, value) {
        commit("setToken", value);
    }
};

const mutations = {
    setEmail(state, email) {
        state.entry.email = email;
    },
    setPassword(state, password) {
        state.entry.password = password;
    },
    setPasswordConfirmation(state, password_confirmation) {
        state.entry.password_confirmation = password_confirmation;
    },
    setToken(state, token) {
        state.entry.token = token;
    },
    setLoading(state, loading) {
        state.loadiing = loading;
    },
    resetState(state) {
        state = Object.assign(state, initialState());
    }
};

export default {
    namespaced: true,
    state: initialState,
    getters, 
    actions,
    mutations
}