import Vue from 'vue'
import Vuex from 'vuex'

import Alert from './modules/alert'
import I18NStore from './modules/i18n'
import Auth from './modules/auth'
import ForgotPassword from './modules/forgotPassword'
import ResetPassword from './modules/resetPassword'
import HerdsIndex from './data/herds'
import HerdsSingle from './data/herds/single'
import AssetsIndex from './data/assets'
import AssetsSingle from './data/assets/single'
import AssetAlertsIndex from './data/assetAlerts'
import MapIndex from './data/map'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    Alert,
    I18NStore,
    Auth,
    ForgotPassword,
    ResetPassword,
    HerdsIndex,
    HerdsSingle,
    AssetsIndex,
    AssetsSingle,
    AssetAlertsIndex,
    MapIndex
  },
  strict: debug
})
