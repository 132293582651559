window._ = require("lodash");
window.objectToFormData = require("object-to-formdata").objectToFormData;

window.axios = require("axios");

window.axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
window.axios.defaults.publicURL = process.env.VUE_APP_PUBLIC_URL;
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
//window.axios.defaults.headers.common["Authorization"] = `Bearer ${window.localStorage.getItem("user-token") || ""}`;

window.axios.defaults.headers.common = {
	Credentials: false,
	Accept: "application/json",
	Authorization: `Bearer ${window.localStorage.getItem("user-token") || ""}`,
};

window.axios.interceptors.request.use((config) => {
	config.headers["Authorization"] =
		"Bearer " + localStorage.getItem("user-token");
	return config;
});

window.axios.interceptors.response.use(
	function(response) {
		return response;
	},
	function(error) {
		if (error.response.status === 401 || error.response.status === 419) {
			localStorage.removeItem("user-token");
			location.assign("/login");
		}

		return Promise.reject(error);
	}
);
